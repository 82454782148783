import React from 'react';
import { Card, Grid, Typography, FormControlLabel, Checkbox, CardContent } from '@mui/material';

function FilterComponent({ filters, handleFilterChange }) {
    return (
        <Card style={{ margin: 10 }}>
            <CardContent style={{ textAlign: "left" }}>
                <Typography style={{ fontFamily: 'Inter', fontWeight: 500, fontSize: 12 }}>Filter</Typography>
                <Grid container spacing={1} direction={{ xs: 'row', md: 'column' }}>
                    <Grid item xs={6}>
                        <FormControlLabel
                            control={<Checkbox checked={filters.DevOps} onChange={handleFilterChange} name="DevOps" />}
                            label={<Typography style={{ fontFamily: 'Inter' }}>DevOps</Typography>}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <FormControlLabel
                            control={<Checkbox checked={filters.ZohoTickets} onChange={handleFilterChange} name="ZohoTickets" />}
                            label={<Typography style={{ fontFamily: 'Inter' }}>zoho Tickets</Typography>}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <FormControlLabel
                            control={<Checkbox checked={filters.SunriseTicket} onChange={handleFilterChange} name="SunriseTicket" />}
                            label={<Typography style={{ fontFamily: 'Inter' }}>Sunrise Tickets</Typography>}
                        />
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
}

export default FilterComponent;

import React, {useEffect, useState} from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import logo from './logo.svg';

import './App.css';
import {TextField} from "@mui/material";
import SearchComponent from "./Components/SearchComponent";

function App() {
    const [apiKey, setApiKey] = useState('');

    useEffect(() => {
        const storedApiKey = localStorage.getItem('x-api-key');
        if (storedApiKey) {
            setApiKey(storedApiKey);
        } else {
            const userInput = prompt("Please enter your API key:");
            if (userInput) {
                localStorage.setItem('x-api-key', userInput);
                setApiKey(userInput);
            }
        }
    }, []);

    return (
      <div className="App">
        <AppBar position="static">
          <Toolbar style={{background: '#001738'}}>

                <img alt='Logo' src='/logo.png' height='40' style={{margin: 10}}/>

                <Typography variant="h6" component="div" style={{fontFamily: "Poppins"}}>
                  BrAIn Librarian
                </Typography>

          </Toolbar>
        </AppBar>

          <SearchComponent/>

      </div>
  );
}

export default App;

import React from 'react';
import { Card, CardContent, Typography } from '@mui/material';

function SearchResult({ result }) {

    const formatDate = (isoDateString) => {
        // Extracting date, month, and year from the input string
        const parts = isoDateString.split(' ')[0].split('.'); // Split by "." and take the first part
        const day = parts[0];
        const month = parts[1];
        const year = parts[2];
        return `${day}.${month}.${year}`;
    };

    return (
        <Card style={{ margin: 10, textAlign: "left" }}>
            <CardContent>
                <div style={{ marginBottom: 5 }}>
                    {/* Tags */}
                    <Typography variant="body2" style={{
                        display: 'inline-block',
                        marginRight: 5,
                        padding: '3px 8px',
                        color: '#ffffff',
                        backgroundColor: '#001738',
                        borderRadius: 5
                    }}>
                        {result.type}
                    </Typography>
                    {result.tfsType && (
                    <Typography variant="body2" style={{
                        display: 'inline-block',
                        marginRight: 5,
                        padding: '3px 8px',
                        color: '#ffffff',
                        backgroundColor: '#f04e23',
                        borderRadius: 5
                    }}>
                        {result.tfsType}
                    </Typography>
                    )}

                    <Typography variant="body2" style={{
                        display: 'inline-block',
                        marginRight: 5,
                        padding: '3px 8px',
                    }}>
                        {Math.round(result.score * 10) / 10}{/* Rounds to 1 decimal place */}% Match
                    </Typography>

                </div>

                <Typography style={{ fontFamily: 'Inter', fontSize: 18, marginBottom: 5, fontWeight: 500 }}>
                    <a href={result.link} target="_blank" rel="noopener noreferrer" style={{
                        color: '#001738',
                        textDecoration: 'none',
                        borderBottom: '1px solid transparent',
                        transition: 'border-color 0.3s',
                        cursor: 'pointer',
                        display: 'inline-block',
                    }}
                       onMouseEnter={(e) => e.target.style.borderBottom = '1px solid #001738'}
                       onMouseLeave={(e) => e.target.style.borderBottom = '1px solid transparent'}
                    >
                        {result.title.endsWith('.') ? result.title.slice(0, -1) : result.title}
                    </a>
                </Typography>

                {result.areaPath && (
                    <Typography style={{ fontFamily: 'Inter', fontSize: 13 }}>
                        <b>Area:</b> {result.areaPath}
                    </Typography>
                )}

                {result.createdDate && (
                    <Typography style={{marginBottom:5, fontFamily: 'Inter', fontSize: 13 }}>
                        <b>Created:</b> {result.createdDate}
                    </Typography>
                )}

                <Typography style={{ fontFamily: 'Inter', fontSize: 15 }}>
                    {result.description}
                </Typography>

                {result.solution && (
                    <Typography style={{ fontFamily: 'Inter', fontSize: 13, marginTop:5 }}>
                        <b>Solution:</b> {result.solution}
                    </Typography>
                )}
            </CardContent>
        </Card>
    );
}

export default SearchResult;

export const handleSearch = async (searchQuery, page, setSearchResults, setInitialSearchInitiated) => {
    try {
        let apiKey = localStorage.getItem('x-api-key');

        if (!apiKey) {
            apiKey = prompt("Please enter your API key:");
            if (apiKey) {
                localStorage.setItem('x-api-key', apiKey);
            } else {
                throw new Error('API key is required.');
            }
        }

        const headers = {
            'Content-Type': 'application/json',
            'x-api-key': apiKey
        };

        let url;

        if (window.location.hostname === 'localhost') {
            // If running on localhost, use localhost:1999
            url = `http://127.0.0.1:1999/search?q=${searchQuery}&page=${page}`;
        } else {
            // Otherwise, use the current host
            url = `https://searchapi.copatools.com/search?q=${searchQuery}&page=${page}`;
        }

        const response = await fetch(url, {
            method: 'GET',
            headers: headers
        });

        if (!response.ok) {
            if (response.status === 401) {
                // Unauthorized, prompt for API key again
                apiKey = prompt("Unauthorized access. Please enter your API key again:");
                if (apiKey) {
                    localStorage.setItem('x-api-key', apiKey);
                    headers['x-api-key'] = apiKey;
                    // Retry the request with the new API key
                    const retryResponse = await fetch(`https://searchapi.copatools.com/search?q=${searchQuery}&page=${page}`, {
                        method: 'GET',
                        headers: headers
                    });
                    if (retryResponse.ok) {
                        const data = await retryResponse.json();
                        setInitialSearchInitiated(true);
                        setSearchResults(data.results);
                        return data.total_hits; // Return total hits
                    }
                }
            }
            throw new Error(`Failed to fetch: ${response.statusText}`);
        }

        const data = await response.json();
        console.log('Search results:', data);
        setInitialSearchInitiated(true);
        setSearchResults(data.results);

        return { totalHits: data.total_hits, processingTime: data.processing_time }; // Return total hits and processing time
    } catch (error) {
        console.error('Error searching:', error.message);
    }
};

export const handleKeyPress = (e, handleSearch) => {
    if (e.key === 'Enter') {
        handleSearch();
    }
};

export const filterResults = (searchResults, filters, setFilteredResults) => {
    const filtered = searchResults.filter(result => {
        if (filters.DevOps && result.type === "DevOps") {
            return true;
        }
        if (filters.SunriseTicket && result.type === "Sunrise Ticket") {
            return true;
        }

        if (filters.ZohoTickets && result.type === "zoho Ticket") {
            return true;
        }
        return false;
    });
    setFilteredResults(filtered);
};
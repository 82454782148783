import React, { useEffect, useState } from 'react';
import {Card, CardContent, TextField, Typography, Grid, Button, Pagination} from '@mui/material';
import Container from "@mui/material/Container";
import FilterComponent from "./FilterComponent";
import { handleSearch, handleKeyPress, filterResults } from '../Logic/search-logic';
import SearchResult from "./SearchResult";
import NoResultsMessage from "./NoResultsMessage";
import Box from "@mui/material/Box"; // Import functions from search-logic.js

function SearchComponent() {
    const [searchResults, setSearchResults] = useState([]);
    const [filteredResults, setFilteredResults] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [filters, setFilters] = useState({ DevOps: true, ZohoTickets: true, SunriseTicket: true });
    const [initialSearchInitiated, setInitialSearchInitiated] = useState(false); // New state variable
    const [currentPage, setCurrentPage] = useState(1); // State for current page
    const [totalPages, setTotalPages] = useState(0); // State for total pages
    const [totalHits, setTotalHits] = useState(0);
    const [processingTime, setProcessingTime] = useState(0); // State for processing time


    useEffect(() => {
        filterResults(searchResults, filters, setFilteredResults);
    }, [searchResults, filters]);

    useEffect(() => {
        if (initialSearchInitiated) {
            filterResults(searchResults, filters, setFilteredResults);
        }
    }, [searchResults, filters, initialSearchInitiated]);

    // Adjust the handleSearchButton function to update totalPages
    const handleSearchButton = async () => {
        try {
            const { totalHits, processingTime } = await handleSearch(searchQuery, 1, setSearchResults, setInitialSearchInitiated);
            if (totalHits) {
                setTotalHits(totalHits); // Set totalHits state
                const pages = Math.ceil(totalHits / 20);
                setTotalPages(pages); // Update total pages for pagination
                setProcessingTime(processingTime); // Set processing time
            }
            setCurrentPage(1);
        } catch (error) {
            console.error('Error searching:', error.message);
        }
    };

    // Adjust the handleSearchWithPagination function to update totalPages
    const handleSearchWithPagination = async (page) => {
        try {
            const { totalHits, processingTime } = await handleSearch(searchQuery, page, setSearchResults, setInitialSearchInitiated);
            if (totalHits) {
                setTotalHits(totalHits); // Set totalHits state
                const pages = Math.ceil(totalHits / 20);
                setTotalPages(pages); // Update total pages for pagination
                setProcessingTime(processingTime); // Set processing time
            }
            setCurrentPage(page);
        } catch (error) {
            console.error('Error searching:', error.message);
        }
    };

    // Function to handle page change
    const handlePageChange = (event, value) => {
        setCurrentPage(value);
        handleSearchWithPagination(value);
        window.scrollTo({ top: 0, behavior: 'smooth' }); // Scroll to the top of the page
    };

    return (
        <Container>
            <Grid container spacing={2}>
                {/* Search Bar */}
                <Grid item xs={11} style={{marginTop:20}}>
                    <TextField
                        label="Search"
                        variant="outlined"
                        fullWidth
                        InputProps={{
                            style: {
                                borderRadius: 25,
                            },
                        }}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                        onKeyPress={(e) => handleKeyPress(e, () => handleSearchWithPagination(1))}
                    />
                </Grid>

                <Grid item xs={1} style={{marginTop:20}}>
                    <Button variant="contained" style={{backgroundColor: '#001738', borderRadius: 25, height:50}} onClick={handleSearchButton}>
                        Search
                    </Button>
                </Grid>

                {/* Display Filters */}
                <Grid item xs={12} md={4} style={{ marginTop: 10 }}>

                    <Box style={{
                        backgroundColor:'#FAFAFA',
                        textAlign: "left",
                        margin:10,
                        marginTop:0,
                        borderRadius:10,
                        padding:20
                    }}>

                        <Typography style={{ fontFamily: 'Inter', fontWeight: 500, fontSize: 12 }}>{totalHits} Results found in {processingTime.toFixed(4)} Seconds</Typography>

                    </Box>

                    <FilterComponent filters={filters} handleFilterChange={(event) => setFilters({ ...filters, [event.target.name]: event.target.checked })} />


                </Grid>


                {/* Display Search Results or Message */}
                <Grid item xs={12} md={8}>
                    {filteredResults.length > 0 ? (
                        <>
                            <Pagination count={totalPages} page={currentPage} onChange={handlePageChange} />
                            {filteredResults.map((result, index) => (
                                <SearchResult key={index} result={result} />
                            ))}
                            <Pagination count={totalPages} page={currentPage} onChange={handlePageChange} />

                        </>
                    ) : (
                        <NoResultsMessage searchQuery={searchQuery} filteredResults={filteredResults} initialSearchInitiated={initialSearchInitiated} />
                    )}
                </Grid>





            </Grid>
        </Container>
    );
}

export default SearchComponent;

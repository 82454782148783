import React from 'react';
import {Card, Paper, Typography} from '@mui/material';

function NoResultsMessage({ searchQuery, filteredResults, initialSearchInitiated }) {
    let message = null;

    if (!searchQuery) {
        message = (
            <Card style={{ padding: 20, textAlign: 'center', marginTop:20 }}>
                <Typography style={{fontFamily: 'Inter', marginBottom: 20}}>Start searching...</Typography>
                <img src='img/cat_searching.gif' alt="Start Searching..." style={{ maxWidth: '100px', height: 'auto' }} />
            </Card>
        );
    }

    else {
        message = (
            <Card style={{ padding: 20, textAlign: 'center', marginTop:20 }}>
                <Typography style={{fontFamily: 'Inter', marginBottom: 20}}>Press enter or the Search Button......</Typography>
                <img src='img/cat_searching.gif' alt="Press enter or the Search Button..." style={{ maxWidth: '100px', height: 'auto' }} />
            </Card>
        );
    }

    // Check if there are no search results
    if (searchQuery && filteredResults.length === 0 && initialSearchInitiated) {
        message =
            <Card style={{ padding: 20, textAlign: 'center', marginTop:20 }}>
                <Typography style={{fontFamily: 'Inter', marginBottom: 20}}>No Results found...</Typography>
                <img src='img/angry_face.gif' alt="No results for this query/filters..." style={{ maxWidth: '100px', height: 'auto' }} />
            </Card>
    }

    return message;
}

export default NoResultsMessage;
